import React from 'react'
import moment from 'moment';
import { nameToAvatarText } from './conversations_helper';

export default class ConversationInbox extends React.Component {
  render() {
    if (this.props.isLoadingConversations && this.props.conversationsLoadCount === 0) {
      return (
        <div className="conversations-loading-container">
          <p>Loading conversations...</p>
        </div>
      );
    }

    if (!this.props.conversations.index?.length) {
      return (
        <p>No messages yet, check back later.</p>
      );
    }

    return (
      (this.props.conversations.index || []).map((conversation, _index) => {
        const displayName = conversation.display_name || conversation.username;
        let avatarHTML = nameToAvatarText(displayName);

        if (conversation.profile_picture_url) {
          avatarHTML = (
            <img src={conversation.profile_picture_url} title={displayName} />
          );
        }

        return (
          <div className="conversations-container" key={conversation.id}>
            <div className="conversation-item">
              <div className="conversation-sender">
                <div className="conversation-avatar">
                  {avatarHTML}
                </div>
                <div className="username">
                  {conversation.username}
                </div>
              </div>
              <div className="conversation-snippet">
                <a href="#" data-conversation-id={conversation.id} onClick={this.props.handleConversationClick}>
                  {moment(conversation.last_message_at).fromNow()} - view conversation
                </a>
              </div>
            </div>
          </div>
        );
      })
    )
  }
}
