import React from 'react';
import MediaStatItem from './media_stat_item';
import MediaViewableItem from './media_viewable_item';;

export default class MediaStory extends React.Component {
  render() {
    let mediaComment;
    let mediaStats;

    if (this.props.commentText) {
      mediaComment = (
        <li className="ig-media-comment">
          <p>{this.props.commentText}</p>
        </li>
      );
    }

    if (!this.props.disableStats) {
      const impressions_count = this.props.media.impressions_count ? this.props.media.impressions_count : 'N/A';
      const reach_count = this.props.media.reach_count ? this.props.media.reach_count : 'N/A';

      mediaStats = (
        <>
          <MediaStatItem
            statLabel="Impressions"
            statValue={impressions_count === -1 ? 0 : impressions_count}
          />
          <MediaStatItem
            statLabel="Reach"
            statValue={reach_count === -1 ? 0 : reach_count}
          />
        </>
      );
    }

    return(
      <div className="ig-media-item story-media-item">
        <MediaViewableItem url={this.props.media.media_url} mediaType={this.props.media.media_type} />
        <ul className="media-stat-container js-media-stat-container">
          {mediaComment}
          <MediaStatItem statLabel="Posted" statValue={this.props.media.posted_at} />
          {mediaStats}
        </ul>
      </div>
    );
  }
}
