import React from 'react';
import MediaPost from './media_post';
import MediaStory from './media_story';
import ConversationInbox from './conversation_inbox';

export default class MediaContainer extends React.Component {
  componentDidMount() {
    this.props.requestConversations();
    this.setRefreshConversationsTimeout();
  }

  componentWillUnmount() {
    clearTimeout(this.requestConversationsTimeout);
    this.requestConversationsTimeout = undefined;
  }

  setRefreshConversationsTimeout() {
    if (!this.props.data.profile?.permissions?.instagram_manage_messages) {
      return;
    }

    this.requestConversationsTimeout = setTimeout(() => {
      if (typeof this.requestConversationsTimeout === 'undefined') {
        return;
      }

      this.props.requestConversations();
      this.setRefreshConversationsTimeout();
    }, 60000);
  }

  render() {
    const media = this.props.media || [];
    const mentions = this.props.mentions || [];

    let mediaToDisplay;

    if (this.props.mediaFilter === 'messages') {
      mediaToDisplay = null; // TODO: Do not render convs, especially for non-social-listening/managed account path.
      // if (this.props.data.profile?.permissions?.instagram_manage_messages) {
      //   mediaToDisplay = (
      //     <ConversationInbox
      //       isLoadingConversations={this.props.isLoadingConversations}
      //       conversationsLoadCount={this.props.conversationsLoadCount}
      //       conversations={this.props.conversations}
      //       handleConversationClick={this.props.handleConversationClick} />
      //   );
      // } else {
      //   mediaToDisplay = (
      //     <p>To view your DMs here, you'll need to <a href="#" onClick={this.props.handleGrantMessagePermissionsClick}>grant access to Instagram messaging</a>.</p>
      //   );
      // }
    } else if (this.props.mediaFilter === 'mentions') {
      if (mentions.length > 0) {
        mediaToDisplay = (
          mentions.map((mention, index) => {
            if (mention.media.is_story) {
              return <MediaStory key={index} media={mention.media} commentText={mention.comment_text} disableStats={true} />
            } else {
              return <MediaPost key={index} media={mention.media} commentText={mention.comment_text} disableStats={true} />
            }
          })
        );
      } else {
        mediaToDisplay = (
          <p>No mentions yet, check back later.</p>
        );
      }
    } else if (media.length > 0) {
      mediaToDisplay = (
        media.map((media_item, index) => {
          if (media_item.is_story) {
            return <MediaStory key={index} media={media_item} />
          } else {
            return <MediaPost key={index} media={media_item} followers_count={this.props.followers_count} />
          }
        })
      );
    } else {
      mediaToDisplay = (
        <p>No {this.props.mediaFilter} yet, check back later.</p>
      );
    }

    return (
      <div className="ig-top-media-container">
        <h2 className="section-header">Overview</h2>
        <div className="media-sub-nav">
          <a href="#" className={`post-sub-nav-item sub-nav-item ${this.props.mediaFilter === 'posts' ? 'active' : ''}`} onClick={this.props.handleFilterClick}>Posts</a>
          <a href="#" className={`story-sub-nav-item sub-nav-item ${this.props.mediaFilter === 'stories' ? 'active' : ''}`} onClick={this.props.handleFilterClick}>Stories</a>
          <a href="#" className={`messages-sub-nav-item sub-nav-item ${this.props.mediaFilter === 'messages' ? 'active' : ''}`} onClick={this.props.handleFilterClick}>Messages</a>
          <a href="#" className={`mentions-sub-nav-item sub-nav-item ${this.props.mediaFilter === 'mentions' ? 'active' : ''}`} onClick={this.props.handleFilterClick}>Mentions</a>
          {/* <a href="#" className="filter-sub-nav-item sub-nav-item">Filter</a> */}
        </div>
        <div className="ig-media-content-container">
          {mediaToDisplay}
        </div>
      </div>
    );
  }
}
