import React from 'react';
import MediaStatItem from './media_stat_item';
import MediaViewableItem from './media_viewable_item';

export default class MediaPost extends React.Component {
  render() {
    let mediaComment;
    let mediaStats;

    if (this.props.commentText) {
      mediaComment = (
        <li className="ig-media-comment">
          <p>{this.props.commentText}</p>
        </li>
      );
    }

    if (!this.props.disableStats) {
      const likes = this.props.media.likes_count ? this.props.media.likes_count : 0;
      const comments = this.props.media.comments_count ? this.props.media.comments_count : 0;
      const followers = this.props.followers_count;
      let engagement_percent = ((likes + comments) * 100 / (followers)).toFixed(1);
      const engagement_to_display = followers === 0 ? "N/A" : engagement_percent + '%';

      mediaStats = (
        <>
          <MediaStatItem statLabel="Engagement" statValue={engagement_to_display} />
          <MediaStatItem statLabel="Likes" statValue={this.props.media.likes_count} />
          <MediaStatItem statLabel="Comments" statValue={this.props.media.comments_count} />
          <MediaStatItem
            statLabel="Impressions"
            statValue={this.props.media.impressions_count ? this.props.media.impressions_count : 'N/A'}
          />
          <MediaStatItem
            statLabel="Reach"
            statValue={this.props.media.reach_count ? this.props.media.reach_count : 'N/A'}
          />
          <MediaStatItem
            statLabel="Saves"
            statValue={this.props.media.saves_count ? this.props.media.saves_count : 'N/A'}
          />
        </>
      );
    }

    return(
      <div className="ig-media-item">
        <MediaViewableItem url={this.props.media.media_url} mediaType={this.props.media.media_type} />
        <ul className="media-stat-container js-media-stat-container">
          {mediaComment}
          <MediaStatItem statLabel="Posted" statValue={this.props.media.posted_at} />
          {mediaStats}
        </ul>
      </div>
    );
  }
}
