import React from 'react';

export default class MediaViewableItem extends React.Component {
  render() {
    let mediaItemToDisplay;

    if (!this.props.url) {
      mediaItemToDisplay = (
        <img src="/images/media/media-expired-square.png" />
      );
    } else if (this.props.mediaType?.toUpperCase() === "VIDEO") {
      mediaItemToDisplay = (
        <video className="ig-media-video" src={this.props.url} autoPlay loop muted></video>
      );
    } else {
      mediaItemToDisplay = (
        <img src={this.props.url} />
      );
    }

    return(
      <>
        {mediaItemToDisplay}
      </>
    );
  }
}
