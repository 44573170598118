export function nameToAvatarText(name) {
  if (!name) {
    return 'U';
  }

  const nameParts = name.split(/[._-\s]/);
  let avatarText = '';

  if (nameParts.length < 2) {
    avatarText = name.substring(0, 2);
  } else {
    for (let i = 0; i < 2; i++) {
      avatarText += nameParts[i][0];
    }
  }

  return avatarText.toUpperCase();
}
