import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

export default class MediaStatItem extends React.Component {
  render() {
    let statBlock = (
      <li>
        <span className="stat-item-label">{this.props.statLabel}</span>
        <span className="stat-item-value">{this.props.statValue}</span>
      </li>
    );

    if (this.props.statLabel == "Posted"){
      statBlock = (
        <li>
          <span className="stat-item-label">{this.props.statLabel}</span>
          <span className="stat-item-value"><Moment date={this.props.statValue} format="MM/DD/YYYY" /></span>
        </li>
      );
    }

    return(
      <>
        { statBlock }
      </>
    );
  }
}
